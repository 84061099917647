import SvgForward from '@sats-group/icons/16/forward';
import cn from 'classnames';
import React from 'react';

import Text from 'sats-ui-lib/react/text';
import VisuallyButton from 'sats-ui-lib/react/visually-button';

import type { UniqueSellingPoint as Props } from './unique-selling-point.types';

const UniqueSellingPoint: React.FunctionComponent<Props> = ({
  link,
  rawIcon,
  tall,
  text,
  title,
}) =>
  React.createElement(
    link ? 'a' : 'div',
    {
      className: cn('unique-selling-point', {
        'unique-selling-point--link': link,
        'unique-selling-point--tall': tall,
      }),
      href: link ? link.href : undefined,
      key: title,
    },
    <React.Fragment>
      <div className="unique-selling-point__icon-container">
        <div
          className={cn('unique-selling-point__icon', {
            'unique-selling-point__icon--link': link,
          })}
          dangerouslySetInnerHTML={{ __html: rawIcon }}
        />
      </div>
      <Text
        className={cn('unique-selling-point__title', {
          'unique-selling-point__title--link': link,
        })}
        elementName="h2"
        size={Text.sizes.headline2}
        tight
        theme={Text.themes.emphasis}
      >
        {title}
      </Text>
      <Text className="unique-selling-point__text" tight>
        {text}
      </Text>
      {link ? (
        <div className="unique-selling-point__overlay">
          <div className="unique-selling-point__overlay-content">
            <Text size={Text.sizes.large} tight theme={Text.themes.emphasis}>
              {link.text}
            </Text>
            <VisuallyButton
              ariaLabel={link.text}
              elementName="div"
              leadingIcon={<SvgForward />}
              role="img"
              variant={VisuallyButton.variants.secondaryWhite}
            />
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );

export default UniqueSellingPoint;
