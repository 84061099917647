import Edit from '@sats-group/icons/18/edit';
import ChevronDown from '@sats-group/icons/24/arrow-down';
import ChevronUp from '@sats-group/icons/24/arrow-up';
import React, { useState } from 'react';

import Banner from 'sats-ui-lib/react/banner';
import Button from 'sats-ui-lib/react/button';
import Expander from 'sats-ui-lib/react/expander';
import HiddenInput from 'sats-ui-lib/react/hidden-input';
import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import LineItems from 'components/line-items/line-items';
import MembershipExplanation from 'components/membership-explanation/membership-explanation';
import RegretTermination from 'components/regret-termination-modal/regret-termination-modal';
import Spinner from 'components/spinner/spinner';
import Collapse from 'react-tiny-collapse';

import type { MyMembershipCard as Props } from './my-membership-card.types';

const MyMembershipCard: React.FunctionComponent<Props> = ({
  actionForms,
  addOns,
  changeMembership,
  explanation,
  header,
  lineItems,
  metadata,
  messages,
  regretTerminationModal,
  summary,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (isSubmitting) {
      event.preventDefault();
    }

    setIsSubmitting(true);
  };

  const [showRegretTerminationModal, setShowRegretTerminationModal] =
    useState<boolean>(false);

  const toggle = () => setExpanded(expanded => !expanded);

  return (
    <div className="my-membership-card">
      {isSubmitting ? <Spinner theme={Spinner.themes.overlay} /> : null}

      <div className="my-membership-card__main">
        <div className="my-membership-card__header">
          <div className="my-membership-card__header-information">
            <div className="my-membership-card__name">
              <Text size={Text.sizes.headline3} theme={Text.themes.emphasis}>
                {header.name}
              </Text>
            </div>
            <div className="my-membership-card__status">
              <div
                className={`my-membership-card__status-pill my-membership-card__status-pill--${header.status}`}
              ></div>
              <Text>{header.statusText}</Text>
            </div>
          </div>
          {changeMembership ? (
            <div className="my-membership-card__header-action">
              <LinkButton
                data-test-action="change"
                leadingIcon={<Edit />}
                wide
                {...changeMembership}
                variant={LinkButton.variants.secondaryWhite}
              />
              <Button
                ariaLabel={'membership expander'}
                leadingIcon={expanded ? <ChevronUp /> : <ChevronDown />}
                onClick={toggle}
                size={Button.sizes.large}
                theme={Button.themes.normal}
                variant={Button.variants.secondaryWhite}
              />
            </div>
          ) : null}
        </div>
        <Collapse duration={50} animateChildren={true} isOpen={expanded}>
          {explanation ? (
            <div className="my-membership-card__explanation">
              <Expander {...explanation} itemRenderer={MembershipExplanation} />
            </div>
          ) : null}
          <div className="my-membership-card__details">
            {metadata.length ? (
              <div className="my-membership-card__metadata">
                {metadata.map(item => (
                  <Text key={item} size={Text.sizes.small}>
                    {item}
                  </Text>
                ))}
              </div>
            ) : null}
            <LineItems {...lineItems} />
            <LineItems {...addOns} />
          </div>
          <div className="my-membership-card__price-summary">
            <LineItems {...summary} />
            {messages.length ? (
              <div className="my-membership-card__messages">
                {messages.map(message => (
                  <Banner key={message.text} {...message} />
                ))}
              </div>
            ) : null}
          </div>
          {showRegretTerminationModal && regretTerminationModal ? (
            <RegretTermination
              {...regretTerminationModal}
              toggleModal={setShowRegretTerminationModal}
            />
          ) : null}

          {actionForms.length ? (
            <div className="my-membership-card__actions">
              {actionForms.map(item => (
                <form
                  action={item.action}
                  method="post"
                  key={item.action}
                  onSubmit={handleSubmit}
                >
                  {item.hiddenInputs.map(input => (
                    <HiddenInput key={input.name} {...input} />
                  ))}
                  <Button
                    data-test-action={item.name}
                    variant={Button.variants.secondary}
                    size={Button.sizes.small}
                    text={item.submit}
                    type="submit"
                  />
                </form>
              ))}
            </div>
          ) : null}
        </Collapse>
      </div>
    </div>
  );
};

export default MyMembershipCard;
