import type { LinkButton } from 'sats-ui-lib/react/link-button/link-button.types';

import type { MediaType } from 'shared/media-type-helper';

import type { ObjectValues } from 'client/types';

import type { BackgroundImage } from '../background-image/background-image.types';
import type { BackgroundVideo } from '../background-video/background-video.types';
import type { CleanLink } from '../clean-link/clean-link.types';
import type { RichText } from '../rich-text/rich-text.types';
import type { Tagline } from '../tagline/tagline.types';

export const themes = {
  leftAlignedMedia: 'media-left',
  rightAlignedMedia: 'media-right',
} as const;

export const variants = {
  featured: 'featured',
} as const;

type ImageBlock = {
  type: MediaType.Image;
  props: BackgroundImage;
};

type VideoBlock = {
  type: MediaType.Video;
  props: BackgroundVideo;
};

export type SplitInfoModule = {
  actions?: LinkButton[];
  disclaimer?: string;
  heading: string;
  link?: LinkButton;
  media: ImageBlock | VideoBlock;
  subButtonLink?: CleanLink;
  tagline?: Tagline;
  text?: RichText;
  theme?: ObjectValues<typeof themes>;
  variant?: ObjectValues<typeof variants>;
};
